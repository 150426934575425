<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card id="borderradius">
        <v-card-text class="pa-5">
          <v-layout :style="'color:' + color.theme" class="mb-4">
            <v-flex d-flex justify-center>
              <h3>{{ $t('expiredrightclick.detail') }}</h3>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')">close</v-icon>
            </v-flex>
          </v-layout>
          <div>
            <!-- ชื่อ -->
            <v-layout row wrap>
              <v-flex xs7 class="pl-4">
                <p>{{ $t("tablefile.filename") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs5 class="pl-4">
                <p class="font-weight-bold">{{ filedata.file_name }}</p>
              </v-flex>
            </v-layout>
            <!-- ผู้สร้าง -->
            <v-layout row wrap>
              <v-flex xs7 class="pl-4">
                <p>{{ $t("tablefile.owner") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs5 class="pl-4">
                <p class="font-weight-bold">{{ $t('default') === 'th' ? filedata.file_owner_th : filedata.file_owner_eng }}</p>
              </v-flex>
            </v-layout>
            <!-- วันที่แจ้งเตือนไฟล์หมดอายุ -->
            <v-layout row wrap>
              <v-flex xs7 class="pl-4">
                <p>{{ $t("tablefile.warning_expiration_time") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs5 class="pl-4">
                <p class="font-weight-bold">
                  <v-chip :color="$vuetify.theme.dark ? 'orange--text orange' : 'orange--text orange lighten-5'" small>
                    {{ formatdatetime(filedata.expiration_time) }}
                  </v-chip>
                </p>
              </v-flex>
            </v-layout>
            <!-- วันที่หมดอายุ -->
            <v-layout row wrap>
              <v-flex xs7 class="pl-4">
                <p>{{ $t("tablefile.expiration_time") }}<span class="float-right">:</span></p>
              </v-flex>
              <v-flex xs5 class="pl-4">
                <p class="font-weight-bold">
                  <v-chip :color="$vuetify.theme.dark ? 'red--text red' : 'red--text red lighten-5'" small>
                    {{ formatdatetime(filedata.warning_expiration_time) }}
                  </v-chip>
                </p>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: ["show", "filedata"],
  data: function() {
    return {};
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "storage_usage",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
    },
  },
  methods: {
    formatdatetime(_datetime) {
      if ( _datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null ) {
        return "-";
      } else {
        let date = `${_datetime.slice(0,4)}-${_datetime.slice(4,6)}-${_datetime.slice(6,8)}`
        return (date);
      }
    },
  },
};
</script>
